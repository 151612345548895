import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SidebarLayoutComponent } from './components/sidebar-layout/sidebar-layout.component';
import { SplitterModule } from '@syncfusion/ej2-angular-layouts';
import { TagComponent } from '@app/shared/components/tag/tag.component';
import { FilterBadgeComponent } from '@app/shared/components/filter-badge/filter-badge.component';
import { InfiniteScrollTriggerDirective } from './directives/infinite-scroll-trigger.directive';
import { UtcDatePipe } from './pipes/utc-date.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectInputComponent } from './components/select-input/select-input.component';
import { TagListComponent } from './components/tag-list/tag-list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SplitterModule,
    NgSelectModule
  ],
  declarations: [
    SidebarLayoutComponent,
    TagComponent,
    FilterBadgeComponent,
    InfiniteScrollTriggerDirective,
    UtcDatePipe,
    SelectInputComponent,
    TagListComponent
  ],
  exports: [
    SidebarLayoutComponent,
    TagComponent,
    FilterBadgeComponent,
    InfiniteScrollTriggerDirective,
    UtcDatePipe,
    SelectInputComponent,
    TagListComponent
  ]
})
export class SharedModule {
}

/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TasklistApiConfiguration, TasklistApiConfigurationParams } from './tasklist-api-configuration';

import { TaskService } from './services/task.service';
import { DataEntryService } from './services/data-entry.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    TaskService,
    DataEntryService,
    TasklistApiConfiguration
  ],
})
export class TasklistApiModule {
  static forRoot(params: TasklistApiConfigurationParams): ModuleWithProviders<TasklistApiModule> {
    return {
      ngModule: TasklistApiModule,
      providers: [
        {
          provide: TasklistApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: TasklistApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('TasklistApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, TaskDataState } from '@app/task-data/state-management/task-data.reducers';

export const TASK_DATA_FEATURE_NAME = 'task-data';
export const getTaskState = createFeatureSelector<TaskDataState>(TASK_DATA_FEATURE_NAME);
export const isInitialized = createSelector(
  getTaskState,
  (state: TaskDataState) => state.initialized
);
export const hasErrors = createSelector(getTaskState, (state) => state.taskListError);

export const {
  selectAll: selectAllTasks,
  selectIds,
  selectEntities: selectTaskEntities
} = adapter.getSelectors(getTaskState);

export const getTaskById = id => createSelector(
  selectTaskEntities,
  entities => entities[id]
);

/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { TasklistApiConfiguration } from '../tasklist-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TaskCreateOrUpdate } from '../models/task-create-or-update';
import { UserTask } from '../models/user-task';


/**
 * Operations tasks.
 */
@Injectable({
  providedIn: 'root',
})
export class TaskService extends BaseService {
  constructor(
    config: TasklistApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTasks
   */
  static readonly GetTasksPath = '/task';

  /**
   * Lists tasks.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTasks$Response(params?: {

    /**
     * The page number to access (0 indexed, defaults to 0)
     */
    page?: number;

    /**
     * The page size requested (defaults to unlimited)
     */
    size?: number;

    /**
     * A collection of sort directives in the format +prop1.
     */
    sort?: string;

    /**
     * To request also tasks that are deferred.
     */
    includeDeferred?: boolean;

    /**
     * Filter for tasks with equal business key.
     */
    businessKey?: string;
  }): Observable<StrictHttpResponse<Array<UserTask>>> {

    const rb = new RequestBuilder(this.rootUrl, TaskService.GetTasksPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('sort', params.sort, {});
      rb.query('includeDeferred', params.includeDeferred, {});
      rb.query('businessKey', params.businessKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserTask>>;
      })
    );
  }

  /**
   * Lists tasks.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTasks(params?: {

    /**
     * The page number to access (0 indexed, defaults to 0)
     */
    page?: number;

    /**
     * The page size requested (defaults to unlimited)
     */
    size?: number;

    /**
     * A collection of sort directives in the format +prop1.
     */
    sort?: string;

    /**
     * To request also tasks that are deferred.
     */
    includeDeferred?: boolean;

    /**
     * Filter for tasks with equal business key.
     */
    businessKey?: string;
  }): Observable<Array<UserTask>> {

    return this.getTasks$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserTask>>) => r.body as Array<UserTask>)
    );
  }

  /**
   * Path part for operation getTaskStream
   */
  static readonly GetTaskStreamPath = '/task-stream';

  /**
   * Stream of user tasks that serves all permitted tasks initially and updates afterwards.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaskStream()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskStream$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UserTask>>> {

    const rb = new RequestBuilder(this.rootUrl, TaskService.GetTaskStreamPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/event-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserTask>>;
      })
    );
  }

  /**
   * Stream of user tasks that serves all permitted tasks initially and updates afterwards.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaskStream$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskStream(params?: {
  }): Observable<Array<UserTask>> {

    return this.getTaskStream$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserTask>>) => r.body as Array<UserTask>)
    );
  }

  /**
   * Path part for operation getTaskUpdates
   */
  static readonly GetTaskUpdatesPath = '/task-updates';

  /**
   * Stream of user tasks that serves updates to tasklist.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaskUpdates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskUpdates$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UserTask>>> {

    const rb = new RequestBuilder(this.rootUrl, TaskService.GetTaskUpdatesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/event-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserTask>>;
      })
    );
  }

  /**
   * Stream of user tasks that serves updates to tasklist.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaskUpdates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskUpdates(params?: {
  }): Observable<Array<UserTask>> {

    return this.getTaskUpdates$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserTask>>) => r.body as Array<UserTask>)
    );
  }

  /**
   * Path part for operation getTaskById
   */
  static readonly GetTaskByIdPath = '/task/{id}';

  /**
   * Lists single task by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaskById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskById$Response(params: {

    /**
     * Task id.
     */
    id: string;
  }): Observable<StrictHttpResponse<UserTask>> {

    const rb = new RequestBuilder(this.rootUrl, TaskService.GetTaskByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserTask>;
      })
    );
  }

  /**
   * Lists single task by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTaskById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskById(params: {

    /**
     * Task id.
     */
    id: string;
  }): Observable<UserTask> {

    return this.getTaskById$Response(params).pipe(
      map((r: StrictHttpResponse<UserTask>) => r.body as UserTask)
    );
  }

  /**
   * Path part for operation createOrUpdateTask
   */
  static readonly CreateOrUpdateTaskPath = '/task/{id}';

  /**
   * Creates or updates a single task.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrUpdateTask$Response(params: {

    /**
     * Task id.
     */
    id: string;

    /**
     * The task data
     */
    body: TaskCreateOrUpdate
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TaskService.CreateOrUpdateTaskPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Creates or updates a single task.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrUpdateTask(params: {

    /**
     * Task id.
     */
    id: string;

    /**
     * The task data
     */
    body: TaskCreateOrUpdate
  }): Observable<void> {

    return this.createOrUpdateTask$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteTask
   */
  static readonly DeleteTaskPath = '/task/{id}';

  /**
   * Deletes a single task.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTask$Response(params: {

    /**
     * Task id.
     */
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TaskService.DeleteTaskPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes a single task.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTask(params: {

    /**
     * Task id.
     */
    id: string;
  }): Observable<void> {

    return this.deleteTask$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}

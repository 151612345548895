import { NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ConfigurationModule, ConfigurationService, CustomConfigurationService } from '@aviatar/configuration';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { KeycloakService, SsoModule } from '@aviatar/sso';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LAUNCHPAD_AVIATAR_API_CONFIG, LaunchpadModule } from '@aviatar/launchpad';
import { AppRoutingModule } from '../app.routing';
import { NotificationBannerComponent } from './components/notification-banner/notification-banner.component';
import { CustomKeycloakInterceptor } from '@app/core/interceptors/custom-keycloak.interceptor';
import { TasklistApiConfiguration } from '@app/gen/tasklist-api/tasklist-api-configuration';
import { AVIATAR_API_CONFIG, AviatarApiClientModule, AviatarApiConfiguration } from '@aviatar/api-client';
import { TasklistApiModule } from '@app/gen/tasklist-api/tasklist-api.module';
import { USER_TRACKING_CONFIG, UserTrackingModule } from '@aviatar/user-tracking';
import { StreamService } from '@app/core/services/stream.service';
import { TagMapperService } from '@app/core/services/tag-mapper.service';
import { ExtensionComponentCacheService } from '@app/core/services/extension-component-cache.service';
import { CC_AVIATAR_API_CONFIG } from '@app/core/aviatar-api-config';
import { PROCESS_MODULE_CONFIG, ProcessModule } from '@aviatar/process';
import { LAZY_ELEMENTS_REGISTRY } from '@angular-extensions/elements';
import { CustomElementsRegistry } from '@app/core/custom-elements-registry';
import { UserNotificationModule } from '@aviatar/user-notification';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ApiCredentialsInterceptor } from './interceptors/api-credentials.interceptor';
import { NotificationInboxConfiguration } from '@app/gen/notification-inbox/notification-inbox-configuration';
import { NOTIFICATION_CONFIG, NotificationsModule } from '@aviatar/notifications';

@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule,
    SsoModule,
    LaunchpadModule,
    AppRoutingModule,
    ConfigurationModule.forRoot(),
    UserTrackingModule,
    TasklistApiModule, // generated Tasklist API client module
    AviatarApiClientModule, // generated AVIATAR API client module
    ProcessModule,
    UserNotificationModule,
    NotificationsModule
  ],
  exports: [
    LaunchpadModule,
    SsoModule,
    NotificationBannerComponent,
    UserNotificationModule,
    LoadingSpinnerComponent,
    NotificationsModule
  ],
  declarations: [
    NotificationBannerComponent,
    LoadingSpinnerComponent
  ],
  providers: [

    /** custom registry for lazy loaded custom elements **/
    {
      provide: LAZY_ELEMENTS_REGISTRY,
      useClass: CustomElementsRegistry
    },

    /** Process Module */
    {
      provide: PROCESS_MODULE_CONFIG,
      useFactory: (configService, customConfigService) => ({
        tasklistApiBaseUrl: CustomConfigurationService.provideConfigurationValue('tasklist-api-base-path')(customConfigService),
        aviatarApi: {
          baseUrl: ConfigurationService.provideConfigurationValue('aviatar-api-base-url')(configService),
          appId: ConfigurationService.provideConfigurationValue('aviatar-api-appid')(configService),
          appKey: ConfigurationService.provideConfigurationValue('aviatar-api-appkey')(configService)
        }
      }),
      deps: [ConfigurationService, CustomConfigurationService]
    },
    /**
     * Launchpad
     */
    {
      provide: LAUNCHPAD_AVIATAR_API_CONFIG,
      useFactory: ConfigurationService.provideConfigurationObject(
        new Map([['appId', 'aviatar-api-appid'], ['appKey', 'aviatar-api-appkey'], ['baseUrl', 'aviatar-api-base-url']])
      ),
      deps: [ConfigurationService]
    },
    /**
     * Notification Module
     */
    {
      provide: NOTIFICATION_CONFIG,
      useValue: {
        serviceWorkerPath: './assets/aviatar-notifications/service-worker.js'
      }
    },
    /**
     * Control Center AVIATAR Client
     */
    {
      provide: CC_AVIATAR_API_CONFIG,
      useFactory: ConfigurationService.provideConfigurationObject(
        new Map([['appId', 'aviatar-api-appid'], ['appKey', 'aviatar-api-appkey'], ['baseUrl', 'aviatar-api-base-url']])
      ),
      deps: [ConfigurationService]
    },

    /**
     * @aviatar/api-client
     */
    {
      provide: AVIATAR_API_CONFIG,
      useFactory: ConfigurationService.provideConfigurationObject(
        new Map([['appId', 'aviatar-api-appid'], ['appKey', 'aviatar-api-appkey'], ['baseUrl', 'aviatar-api-base-url']])
      ),
      deps: [ConfigurationService]
    },

    /**
     * Custom generated REST API client modules
     */
    {
      provide: TasklistApiConfiguration,
      useFactory: CustomConfigurationService.provideConfigurationObject(new Map([
        ['rootUrl', 'tasklist-api-base-path']
      ])),
      deps: [CustomConfigurationService]
    },
    {
      provide: AviatarApiConfiguration,
      useFactory: CustomConfigurationService.provideConfigurationObject(new Map([
        ['rootUrl', 'aviatar-api-base-url']
      ])),
      deps: [ConfigurationService]
    },
    {
      provide: NotificationInboxConfiguration,
      useFactory: CustomConfigurationService.provideConfigurationObject(new Map([
        ['rootUrl', 'notification-api-base-path']
      ])),
      deps: [CustomConfigurationService]
    },

    /**
     * Amplitude user tracking
     */
    {
      provide: USER_TRACKING_CONFIG,
      useFactory: CustomConfigurationService.provideConfigurationObject(new Map([
        ['stage', 'deployment-stage'],
        ['enabled', 'usertracking-enabled'],
        ['amplitudeApiKey', 'usertracking-amplitude-apikey'],
        ['amplitudeApiEndpoint', 'usertracking-amplitude-api-endpoint'],
        ['appName', 'usertracking-appname']
      ])),
      deps: [CustomConfigurationService]
    },

    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomKeycloakInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiCredentialsInterceptor,
      multi: true
    },
    KeycloakService,
    StreamService,
    TagMapperService,
    ExtensionComponentCacheService
  ]
})
export class CoreModule {
}

/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { TasklistApiConfiguration } from '../tasklist-api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DataEntriesRequest } from '../models/data-entries-request';
import { DataEntriesSearchRequest } from '../models/data-entries-search-request';
import { DataEntryCreateOrUpdate } from '../models/data-entry-create-or-update';
import { DataEntryFilterOptions } from '../models/data-entry-filter-options';
import { UserDataEntry } from '../models/user-data-entry';


/**
 * Data entries.
 */
@Injectable({
  providedIn: 'root',
})
export class DataEntryService extends BaseService {
  constructor(
    config: TasklistApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDataEntry
   */
  static readonly GetDataEntryPath = '/data-entries/{entryType}/{entryId}';

  /**
   * Returns a single data entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataEntry()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataEntry$Response(params: {

    /**
     * The type of the data entry
     */
    entryType: string;

    /**
     * The id of the data entry
     */
    entryId: string;
  }): Observable<StrictHttpResponse<UserDataEntry>> {

    const rb = new RequestBuilder(this.rootUrl, DataEntryService.GetDataEntryPath, 'get');
    if (params) {
      rb.path('entryType', params.entryType, {});
      rb.path('entryId', params.entryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDataEntry>;
      })
    );
  }

  /**
   * Returns a single data entry.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDataEntry$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataEntry(params: {

    /**
     * The type of the data entry
     */
    entryType: string;

    /**
     * The id of the data entry
     */
    entryId: string;
  }): Observable<UserDataEntry> {

    return this.getDataEntry$Response(params).pipe(
      map((r: StrictHttpResponse<UserDataEntry>) => r.body as UserDataEntry)
    );
  }

  /**
   * Path part for operation createOrUpdateDataEntry
   */
  static readonly CreateOrUpdateDataEntryPath = '/data-entries/{entryType}/{entryId}';

  /**
   * Creates or updates a single data entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateDataEntry()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrUpdateDataEntry$Response(params: {

    /**
     * The type of the data entry
     */
    entryType: string;

    /**
     * The id of the data entry
     */
    entryId: string;

    /**
     * The data entry data
     */
    body: DataEntryCreateOrUpdate
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DataEntryService.CreateOrUpdateDataEntryPath, 'put');
    if (params) {
      rb.path('entryType', params.entryType, {});
      rb.path('entryId', params.entryId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Creates or updates a single data entry.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateDataEntry$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOrUpdateDataEntry(params: {

    /**
     * The type of the data entry
     */
    entryType: string;

    /**
     * The id of the data entry
     */
    entryId: string;

    /**
     * The data entry data
     */
    body: DataEntryCreateOrUpdate
  }): Observable<void> {

    return this.createOrUpdateDataEntry$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteDataEntry
   */
  static readonly DeleteDataEntryPath = '/data-entries/{entryType}/{entryId}';

  /**
   * Deletes a single data entry.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDataEntry()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDataEntry$Response(params: {

    /**
     * The type of the data entry
     */
    entryType: string;

    /**
     * The id of the data entry
     */
    entryId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DataEntryService.DeleteDataEntryPath, 'delete');
    if (params) {
      rb.path('entryType', params.entryType, {});
      rb.path('entryId', params.entryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes a single data entry.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteDataEntry$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDataEntry(params: {

    /**
     * The type of the data entry
     */
    entryType: string;

    /**
     * The id of the data entry
     */
    entryId: string;
  }): Observable<void> {

    return this.deleteDataEntry$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDataEntriesFilterOptions
   */
  static readonly GetDataEntriesFilterOptionsPath = '/data-entries/filter-options';

  /**
   * Maximum Filter Options of a user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataEntriesFilterOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataEntriesFilterOptions$Response(params?: {

    /**
     * The type of the data entry
     */
    entryType?: Array<string>;
  }): Observable<StrictHttpResponse<DataEntryFilterOptions>> {

    const rb = new RequestBuilder(this.rootUrl, DataEntryService.GetDataEntriesFilterOptionsPath, 'get');
    if (params) {
      rb.query('entryType', params.entryType, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DataEntryFilterOptions>;
      })
    );
  }

  /**
   * Maximum Filter Options of a user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDataEntriesFilterOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataEntriesFilterOptions(params?: {

    /**
     * The type of the data entry
     */
    entryType?: Array<string>;
  }): Observable<DataEntryFilterOptions> {

    return this.getDataEntriesFilterOptions$Response(params).pipe(
      map((r: StrictHttpResponse<DataEntryFilterOptions>) => r.body as DataEntryFilterOptions)
    );
  }

  /**
   * Path part for operation searchDataEntries
   */
  static readonly SearchDataEntriesPath = '/data-entries/search';

  /**
   * Lists data entries.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchDataEntries()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchDataEntries$Response(params?: {
    body?: DataEntriesSearchRequest
  }): Observable<StrictHttpResponse<Array<UserDataEntry>>> {

    const rb = new RequestBuilder(this.rootUrl, DataEntryService.SearchDataEntriesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDataEntry>>;
      })
    );
  }

  /**
   * Lists data entries.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchDataEntries$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchDataEntries(params?: {
    body?: DataEntriesSearchRequest
  }): Observable<Array<UserDataEntry>> {

    return this.searchDataEntries$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDataEntry>>) => r.body as Array<UserDataEntry>)
    );
  }

  /**
   * Path part for operation getDataEntriesStream
   */
  static readonly GetDataEntriesStreamPath = '/data-entries/stream';

  /**
   * Stream of data entries updates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataEntriesStream()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataEntriesStream$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UserDataEntry>>> {

    const rb = new RequestBuilder(this.rootUrl, DataEntryService.GetDataEntriesStreamPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/event-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDataEntry>>;
      })
    );
  }

  /**
   * Stream of data entries updates.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDataEntriesStream$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataEntriesStream(params?: {
  }): Observable<Array<UserDataEntry>> {

    return this.getDataEntriesStream$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDataEntry>>) => r.body as Array<UserDataEntry>)
    );
  }

  /**
   * Path part for operation getDataEntriesDeprecated
   */
  static readonly GetDataEntriesDeprecatedPath = '/data-entries';

  /**
   * Lists data entries. Deprecated. Please use /data-entries/search instead.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataEntriesDeprecated()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  getDataEntriesDeprecated$Response(params?: {
    body?: DataEntriesRequest
  }): Observable<StrictHttpResponse<Array<UserDataEntry>>> {

    const rb = new RequestBuilder(this.rootUrl, DataEntryService.GetDataEntriesDeprecatedPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDataEntry>>;
      })
    );
  }

  /**
   * Lists data entries. Deprecated. Please use /data-entries/search instead.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDataEntriesDeprecated$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  getDataEntriesDeprecated(params?: {
    body?: DataEntriesRequest
  }): Observable<Array<UserDataEntry>> {

    return this.getDataEntriesDeprecated$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDataEntry>>) => r.body as Array<UserDataEntry>)
    );
  }

  /**
   * Path part for operation getDataEntriesStreamDeprecated
   */
  static readonly GetDataEntriesStreamDeprecatedPath = '/data-entries-stream';

  /**
   * Stream of data entries updates. Deprecated. Please use /data-entries/stream instead.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataEntriesStreamDeprecated()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getDataEntriesStreamDeprecated$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UserDataEntry>>> {

    const rb = new RequestBuilder(this.rootUrl, DataEntryService.GetDataEntriesStreamDeprecatedPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/event-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDataEntry>>;
      })
    );
  }

  /**
   * Stream of data entries updates. Deprecated. Please use /data-entries/stream instead.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDataEntriesStreamDeprecated$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getDataEntriesStreamDeprecated(params?: {
  }): Observable<Array<UserDataEntry>> {

    return this.getDataEntriesStreamDeprecated$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDataEntry>>) => r.body as Array<UserDataEntry>)
    );
  }

  /**
   * Path part for operation getDataEntryFilterOptionsDeprecated
   */
  static readonly GetDataEntryFilterOptionsDeprecatedPath = '/data-entry/filteroptions';

  /**
   * Maximum Filter Options of a user. Deprecated. Please use /data-entries/filter-options instead.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataEntryFilterOptionsDeprecated()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getDataEntryFilterOptionsDeprecated$Response(params?: {

    /**
     * The type of the data entry
     */
    entryType?: Array<string>;
  }): Observable<StrictHttpResponse<DataEntryFilterOptions>> {

    const rb = new RequestBuilder(this.rootUrl, DataEntryService.GetDataEntryFilterOptionsDeprecatedPath, 'get');
    if (params) {
      rb.query('entryType', params.entryType, {"style":"form","explode":false});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DataEntryFilterOptions>;
      })
    );
  }

  /**
   * Maximum Filter Options of a user. Deprecated. Please use /data-entries/filter-options instead.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDataEntryFilterOptionsDeprecated$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getDataEntryFilterOptionsDeprecated(params?: {

    /**
     * The type of the data entry
     */
    entryType?: Array<string>;
  }): Observable<DataEntryFilterOptions> {

    return this.getDataEntryFilterOptionsDeprecated$Response(params).pipe(
      map((r: StrictHttpResponse<DataEntryFilterOptions>) => r.body as DataEntryFilterOptions)
    );
  }

}

import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { KeycloakService } from '@aviatar/sso';
import { ProcessEngineResolverService } from '@app/core/services/process-engine-resolver.service';
import { AviatarApiConfig, CC_AVIATAR_API_CONFIG } from '@app/core/aviatar-api-config';

@Injectable()
export class ApiCredentialsInterceptor implements HttpInterceptor {

  constructor(@Inject(CC_AVIATAR_API_CONFIG) private aviatarApiConfig: AviatarApiConfig) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add app_id and app_key to any request that goes to AVIATAR API
    if (request.url.startsWith(this.aviatarApiConfig.baseUrl) && !request.headers['app_id']) {
      request = request.clone({
        setHeaders: {
          app_id: this.aviatarApiConfig.appId,
          app_key: this.aviatarApiConfig.appKey
        }
      });
    }
    return next.handle(request);
  }
}

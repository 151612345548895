import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { KeycloakService, SsoModuleConfig } from '@aviatar/sso';
import { environment } from './environments/environment';
import { APP_CONFIG, CONFIG_URL, CUSTOM_APP_CONFIG, CUSTOM_CONFIG_URL } from '@aviatar/configuration';
import { from, zip } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('ORg4AjUWIQA/Gnt2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXhTcURiXHtbc31TTmc=');

if (environment.production) {
  enableProdMode();
}

const configurl = '/config/aviatar-api-config.json';
const customConfigUrl = '/config/custom-config.json';

const appConfig$ = from(fetch(configurl)).pipe(
  flatMap(response => from(response.json())),
);
const customConfig$ = from(fetch(customConfigUrl)).pipe(
  flatMap(response => from(response.json()))
);

zip(appConfig$, customConfig$)
  .subscribe(([appConfig, customConfig]) => {

    const SSO_CONFIG: SsoModuleConfig = {
      keycloakConfig: '/config/keycloak.json',
      bearerWhiteList: new SsoModuleConfig().bearerWhiteList
        .concat(environment.bearerWhitelist)
        .concat(customConfig['tasklist-api-base-path']) // Always send the bearer to the tasklist api
        .concat(appConfig['aviatar-api-base-url']) // Always send the bearer to the aviatar api
        .concat(customConfig['notification-api-base-path']), // Always send the bearer to the notification api,
      requiredRoles: ['user'],
      keycloakInitConfig: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/sso-silent-check.html'
      }
    };

    // initialize keycloak and bootstrap application
    KeycloakService.init(SSO_CONFIG)
      .then(() => {
        platformBrowserDynamic([
          {
            provide: CONFIG_URL,
            useValue: configurl
          },
          {
            provide: CUSTOM_CONFIG_URL,
            useValue: customConfigUrl
          },
          {
            provide: APP_CONFIG,
            useValue: appConfig
          },
          {
            provide: CUSTOM_APP_CONFIG,
            useValue: customConfig
          }
        ]).bootstrapModule(AppModule);
      });
  });

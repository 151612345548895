import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { KeycloakService } from '@aviatar/sso';
import { ProcessEngineResolverService } from '@app/core/services/process-engine-resolver.service';

@Injectable()
export class CustomKeycloakInterceptor implements HttpInterceptor {

  constructor(private keycloakService: KeycloakService,
              private processEngineResolver: ProcessEngineResolverService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // in addition to the default keycloak interceptor provided by @aviatar/sso module
    // this interceptor checks if the request URL is a process engine

    if (!this.keycloakService.inBearerWhitelist(request.url) &&
      !this.processEngineResolver.getProcessEngines().find(engine => request.url.startsWith(engine.engineBaseUrl))) {
      return next.handle(request);
    }

    return from(this.keycloakService.getToken()).pipe(
      mergeMap((token: string) => {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
        return next.handle(request);
      }));
  }
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ProcessEngine, ProcessService } from '@aviatar/api-client';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { Severity, UserNotificationService } from '@aviatar/user-notification';

@Injectable({
  providedIn: 'root'
})
export class ProcessEngineResolverService {

  // cache of available process engines
  private processEngines: ProcessEngine[] = [];
  private processEngines$: Observable<ProcessEngine[]>;

  constructor(private processService: ProcessService,
              private userNotification: UserNotificationService) {
    this.processEngines$ =
      this.processService.getProcessEngines({
        app_id: ''
      }).pipe(
        catchError(e => {
          this.userNotification.addAlert({
            severity: Severity.WARNING,
            message: 'Some data could not be loaded. Opening tasks in the Inbox might not work as expected. Please try reloading this page.',
            dismissible: true
          });
          console.error(`Could not load available process engines.`, e);
          return of([]);
        }),
        tap((engines: ProcessEngine[]) => this.processEngines = engines),
        shareReplay()
      );
  }

  public initialize(): Observable<ProcessEngine[]> {
    return this.processEngines$;
  }

  public getProcessEngines(): ProcessEngine[] {
    return this.processEngines;
  }

  public getProcessEngine(processEngineId: string): Observable<ProcessEngine> {
    return this.processEngines$.pipe(
      map(engines => engines.find(engine => engine.id === processEngineId))
    );
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

const DEFAULT: TagVisualization = {
  icon: 'mdi mdi-tag',
  color: 'badge-secondary'
};

type TagMappingType = Record<string, TagVisualization>;

@Injectable({
  providedIn: 'root'
})
export class TagMapperService {

  private mappings$: Observable<Map<string, TagVisualization>>;

  constructor(private http: HttpClient) {
    this.mappings$ = this.http.get<TagMappingType>('/assets/tag-mapping.json').pipe(
      catchError(e => {
        console.error('Could not load tag-mapping.json. Application should work, but tag visualization is ugly.', e);
        return of({})
      }),
      map(object => new Map(Object.entries(object)) as Map<string, TagVisualization>),
      shareReplay()
    );
  }

  public initialize(): Observable<Map<string, TagVisualization>> {
    return this.mappings$;
  }

  public getAvailableTagMappings(): Observable<Map<string, TagVisualization>> {
    return this.mappings$;
  }

  public getVisualizationAsync(tagType: string): Observable<TagVisualization> {
    return this.mappings$.pipe(
      map(mappings => {
        if (mappings.has(tagType)) {
          return {
            icon: mappings.get(tagType).icon || DEFAULT.icon,
            color: mappings.get(tagType).color || DEFAULT.color,
            background: mappings.get(tagType).background || DEFAULT.background,
            text: mappings.get(tagType).text
          };
        }
        return DEFAULT;
      })
    );
  }

}

export interface TagVisualization {
  icon: string;
  color?: string;
  background?: string;
  text?: string;
}

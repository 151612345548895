<ejs-splitter #horizontal separatorSize="2" class="h-100 w-100"
              [ngClass]="sidebarVisible ? 'sidebar-open' : 'sidebar-closed'">
  <e-panes>
    <e-pane [size]="defaultSidebarSize"
            min="300px"
            [collapsible]="true">
      <ng-template #content>
        <div class="avi-site-content-aside-primary w-100 h-100" [ngClass]="sidebarVisible ? 'sidebar-visible' : 'collapsed'">
          <div class="avi-site-content-aside-toggle-wrap row g-0 p-0">
            <div class="col">
              <ng-content select="[sidebar-actions]" *ngIf="sidebarVisible"></ng-content>
            </div>
            <div class="col-auto py-2">
              <button class="avi-site-content-aside-toggle" (click)="toggleSidebar()">
                <span class="sr-only visually-hidden">Toggle aside</span>
              </button>
            </div>
          </div>
          <div class="avi-site-content-aside-content overflow-auto">
            <ng-content select="[sidebar]"></ng-content>
          </div>
        </div>
      </ng-template>
    </e-pane>
    <e-pane min="400px" width="100%">
      <ng-template #content>
        <div class="h-100">
          <ng-content select="[main]"></ng-content>
        </div>
      </ng-template>
    </e-pane>
  </e-panes>
</ejs-splitter>

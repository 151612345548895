import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { ExtensionComponent, ExtensionComponentService } from '@aviatar/api-client';

@Injectable({
  providedIn: 'root'
})
export class ExtensionComponentCacheService {

  private cache = new Map<string, Subject<ExtensionComponent[]>>();

  constructor(private extensionComponentService: ExtensionComponentService) {
  }

  /**
   * Returns Extension Components from Discovery API but caches results so that further
   * requests get the cached result.
   *
   * @param context
   * @param type
   */
  public getExtensionComponents(context: string, type: string): Observable<ExtensionComponent[]> {

    const cacheKey = `${context}/${type}`;

    if (!this.cache.get(cacheKey)) {
      this.cache.set(cacheKey, new ReplaySubject<ExtensionComponent[]>(1));
      this.extensionComponentService.getExtensionComponents({
        app_id: '',
        context,
        type
      }).subscribe(components => {
        this.cache.get(cacheKey).next(components);
      });
    }

    return this.cache.get(cacheKey);
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-badge',
  templateUrl: './filter-badge.component.html',
  styleUrls: ['./filter-badge.component.scss']
})
export class FilterBadgeComponent implements OnInit {

  @Input()
  text: string;

  @Input()
  active = false;

  @Input()
  isRemovable: boolean;

  @Output()
  activeChanged = new EventEmitter<boolean>();

  @Output()
  removed = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  toggleActive() {
    this.activeChanged.emit(!this.active);
  }

  remove() {
    this.removed.emit();
  }

}

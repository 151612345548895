<aviatar-app-block [appName]="'Control Center'">

  <app-loading-spinner></app-loading-spinner>
  <aviatar-unsupported-browser-warning></aviatar-unsupported-browser-warning>

  <div class="app-component" *ngIf="isTaskDataInitialized$ | async">
    <app-notification-banner></app-notification-banner>

    <aviatar-header [title]="'Control Center'" [mobileCompact]="true">
      <aviatar-launchpad></aviatar-launchpad>
      <aviatar-user-menu></aviatar-user-menu>
      <aviatar-notifications></aviatar-notifications>
      <aviatar-user-feedback></aviatar-user-feedback>
      <aviatar-loading-indicator></aviatar-loading-indicator>
      <aviatar-incident-status ngProjectAs="aviatar-notifications" [components]="relevantStatusPageComponents"></aviatar-incident-status>
      <aviatar-user-notification></aviatar-user-notification>
    </aviatar-header>

    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <div class="navbar-collapse" id="navbarColor01">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <a class="nav-link" [routerLink]="'/inbox'"
                 routerLinkActive="active">Task List
              </a>
            </li>
            <li class="nav-item" *ngIf="(hasAcTroubleshootingAccess$ | async) === true">
              <a class="nav-link" [routerLink]="'/ac-troubleshooting-history'"
                 routerLinkActive="active">A/C Troubleshooting History
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="'/case-list'"
                 routerLinkActive="active">Case Files
              </a>
            </li>
            <li class="nav-item" *ngIf="hasProcessStarters">
              <a class="nav-link" [routerLink]="'/process-start'"
                 routerLinkActive="active">Start Process
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="'/notifications'" routerLinkActive="active">Notifications</a>
            </li>
          </ul>
        </div>
        <aviatar-release-notes-button [applicationId]="applicationApiAppId"></aviatar-release-notes-button>
      </div>
    </nav>

    <div class="avi-site-content-area">
      <router-outlet></router-outlet>
    </div>
  </div>
</aviatar-app-block>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {

  private isHidden$ = new BehaviorSubject(true);

  constructor() {
  }

  show() {
    this.isHidden$.next(false);
  }

  hide() {
    this.isHidden$.next(true);
  }

  isHidden(): Observable<boolean> {
    return this.isHidden$;
  }

}

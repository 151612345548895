import { Actions, TASKS_ADD, TASKS_LOAD_ERROR, TASKS_LOADED, TASKS_REMOVE } from './task-data.actions';
import { UserTask } from '@app/gen/tasklist-api/models';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const adapter: EntityAdapter<UserTask> = createEntityAdapter<UserTask>({});

export interface TaskDataState extends EntityState<UserTask> {
  initialized: boolean;
  taskListError: boolean;
}

export const initialState: TaskDataState = adapter.getInitialState({
  initialized: false,
  taskListError: false
});

export const taskDataReducer = (state: TaskDataState = initialState, action: Actions): TaskDataState => {
  switch (action.type) {
    case TASKS_LOADED: {
      return adapter.upsertMany(
        action.tasks,
        { ...state, initialized: true }
      );
    }
    case TASKS_ADD: {
      return adapter.upsertMany( // upsert because we may have already loaded and added the selected tasks previously
        action.tasks,
        { ...state }
      );
    }
    case TASKS_REMOVE: {
      return adapter.removeMany(
        action.taskIds,
        { ...state }
      );
    }
    case TASKS_LOAD_ERROR:
      return {
        ...state,
        taskListError: true
      }
    default:
      return state;
  }

};

import { Action } from '@ngrx/store';
import { UserTask } from '@app/gen/tasklist-api//models';

export const TASK_LOAD = '[TASKS_DATA] LOAD_INITIALIZED';
export const TASKS_LOADED = '[TASKS_DATA] LOADED';
export const TASKS_ADD = '[TASKS_DATA] ADD';
export const TASKS_REMOVE = '[TASKS_DATA] REMOVE';
export const TASKS_LOAD_ERROR = '[TASKS_DATA] LOAD ERROR';

export class TaskLoadInitializeAction implements Action {
  readonly type = TASK_LOAD;

  public constructor() {
  }
}

export class TasksLoadedAction implements Action {
  readonly type = TASKS_LOADED;

  public constructor(public tasks: UserTask[]) {
  }
}

export class TasksAddAction implements Action {
  readonly type = TASKS_ADD;

  public constructor(public tasks: UserTask[]) {
  }
}

export class TasksRemoveAction implements Action {
  readonly type = TASKS_REMOVE;

  public constructor(public taskIds: string[]) {
  }
}

export class TasksLoadErrorAction implements Action {
  readonly type = TASKS_LOAD_ERROR;
}

export type Actions =
  | TaskLoadInitializeAction
  | TasksLoadedAction
  | TasksAddAction
  | TasksRemoveAction
  | TasksLoadErrorAction;

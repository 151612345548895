/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationInboxConfiguration {
  rootUrl: string = 'http://localhost:8080/notification-v2';
}

/**
 * Parameters for `NotificationInboxModule.forRoot()`
 */
export interface NotificationInboxConfigurationParams {
  rootUrl?: string;
}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  appId: 'da2f1b74',
  appKey: 'FOR-DEMO-ONLY-DO-NOT-USE-FOR-CUSTOM-APP',
  bearerWhitelist: [
    'http://localhost:4200',
    'https://api-dev-staging.apps.aviatar.io'
  ],
  acTroubleshootingAccess: {
    resource: 'controlcenter',
    role: 'journal_access'
  },
  customElementsUrl: undefined
};

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationBannerService {

  private text$ = new Subject<string>();

  constructor() { }

  public notify(text: string) {
    this.text$.next(text);
  }

  public getText(): Observable<string> {
    return this.text$;
  }

}

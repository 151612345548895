import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Tag } from '@app/shared/components/tag/tag.component';

@Component({
  selector: 'cc-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagListComponent implements OnChanges {

  @Input()
  tags: Tag[] = [];

  @Input()
  hiddenTypes: string[] = [];

  @Input()
  specialTypes: string[] = [];

  tagsWithoutSpecialTypes: Tag[] = [];

  tagsWithSpecialTypes: Tag[] = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.computeActualTags();
  }

  private computeActualTags() {
    const tags = this.tags?.map(tag => ({ type: tag.type.toUpperCase(), ...tag })) || [];
    const specialTypes = this.specialTypes?.map(specialType => specialType.toUpperCase()) || [];
    const hiddenTypes = this.hiddenTypes?.map(hiddenType => hiddenType.toUpperCase()) || [];

    this.tagsWithoutSpecialTypes = tags.filter(e => !hiddenTypes.includes(e.type) && !specialTypes.includes(e.type));
    // Sort alphanumerically
    this.tagsWithoutSpecialTypes.sort((lhs, rhs) => {
      let result = lhs.type.localeCompare(rhs.type);
      if (result === 0) {
        result = lhs.value.localeCompare(lhs.value);
        if (result === 0) {
          result = (lhs.label || '').localeCompare(rhs.label || '');
        }
      }
      return result;
    });
    this.tagsWithSpecialTypes = tags.filter(e => !hiddenTypes.includes(e.type) && specialTypes.includes(e.type));
    // Sort by special type order, i.e. make the special type order configurable.
    this.tagsWithSpecialTypes.sort((lhs, rhs) => specialTypes.indexOf(lhs.type) - specialTypes.indexOf(rhs.type));
  }
}

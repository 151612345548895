import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'utcDate'
})
export class UtcDatePipe extends DatePipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) locale: string) {
    super(locale);
  }

  transform(value: null | undefined | string, args?: string): null;

  transform(value: Date | string): string | null {
    return super.transform(value, 'yyyy-MM-dd HH:mm', 'UTC');
  }

}

<ng-select
        [name]="name"
        [items]="items"
        [multiple]="multiple"
        [readonly]="readonly"
        [(ngModel)]="model"
        (change)="onChange($event)"
        [placeholder]="placeholder"
        [loading]="loading"
        [loadingText]="loadingText"
        [notFoundText]="notFoundText"
        [searchFn]="searchFn"
        [virtualScroll]="virtualScroll"
        [bindLabel]="bindLabel"
        [bindValue]="bindValue"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="closeOnSelect"
        bufferAmount="30"
>
  <ng-template ng-header-tmp *ngIf="clearable">
    <div class="combo-box-clear">
      <a (click)="clear()">Clear</a>
    </div>
  </ng-template>
</ng-select>
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'inbox',
    pathMatch: 'prefix',
    loadChildren: () => import('./task-inbox/task-inbox.module').then(m => m.TaskInboxModule)
  },
  {
    path: 'ac-troubleshooting-history',
    pathMatch: 'prefix',
    loadChildren: () => import('./ac-troubleshooting-history/ac-troubleshooting-history.module').then(m => m.AcTroubleshootingHistoryModule)
  },
  {
    path: 'journal',
    pathMatch: 'prefix',
    redirectTo: 'ac-troubleshooting-history'
  },
  {
    path: 'case-list',
    pathMatch: 'prefix',
    loadChildren: () => import('./case-list/case-list.module').then(m => m.CaseListModule)
  },
  {
    path: 'process-start',
    pathMatch: 'prefix',
    loadChildren: () => import('./process-start/process-start.module').then(m => m.ProcessStartModule)
  },
  {
    path: 'notifications',
    pathMatch: 'prefix',
    loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule)
  },
  {
    path: '',
    redirectTo: 'inbox',
    pathMatch: 'full'
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'inbox'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, useHash: false, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

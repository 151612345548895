import { LazyElementsRegistry } from '@angular-extensions/elements';

export class CustomElementsRegistry extends Map<string, Promise<void>> implements LazyElementsRegistry {

  private loadedScripts = new Map<string, Promise<void>>();

  get(urlString: string): Promise<void> {
    urlString = CustomElementsRegistry.removeQueryParams(urlString);
    return this.loadedScripts.get(urlString);
  }

  has(urlString: string): boolean {
    urlString = CustomElementsRegistry.removeQueryParams(urlString);
    return this.loadedScripts.has(urlString);
  }

  set(urlString: string, notifier: Promise<void>): this {
    urlString = CustomElementsRegistry.removeQueryParams(urlString);
    if (!this.has(urlString)) {
      this.loadedScripts.set(urlString, notifier);
    }
    return this;
  }

  static removeQueryParams(urlString: string): string {
    return urlString.split('?')[0].split('#')[0];
  }

}

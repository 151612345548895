import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UserTask } from '@app/gen/tasklist-api/models';
import { StreamService } from '@app/core/services/stream.service';
import { TasklistApiConfiguration } from '@app/gen/tasklist-api/tasklist-api-configuration';
import { TaskDataState } from '@app/task-data/state-management/task-data.reducers';
import { TASK_LOAD, TasksAddAction, TasksLoadedAction, TasksLoadErrorAction, TasksRemoveAction } from '@app/task-data/state-management/task-data.actions';
import { TaskService } from '@app/gen/tasklist-api/services/task.service';
import { isInitialized } from '@app/task-data/state-management/task-data.selectors';

@Injectable()
export class TaskDataEffects {

  constructor(
    private actions$: Actions,
    private store: Store<TaskDataState>,
    private streamService: StreamService,
    private taskService: TaskService,
    private tasklistApiConfiguration: TasklistApiConfiguration
  ) {
  }


  initialLoad$ = createEffect(() => this.actions$.pipe(
    ofType(TASK_LOAD),
    switchMap(() => this.taskService.getTasks({ // initial load of a few tasks
      page: 0,
      size: 100,
      sort: '-createTime',
      includeDeferred: true
    }).pipe(
      takeUntil(this.store.select(isInitialized).pipe(filter(initialized => initialized)))
    )),
    map(tasks => new TasksLoadedAction(tasks)),
    catchError(error => {
      console.error('Could not load tasks. Maybe the API did not respond in time. -- initial', error);
      return [
        new TasksLoadedAction([]),
        new TasksLoadErrorAction()
      ];
    })
  ));

  complete$ = createEffect(() => this.actions$.pipe(
    ofType(TASK_LOAD),
    switchMap(() => this.taskService.getTasks({
      includeDeferred: true
    })),
    map(tasks => new TasksLoadedAction(tasks)),
    catchError(error => {
      console.error('Could not load tasks. Maybe the API did not respond in time. -- complete', error);
      return [
        new TasksLoadedAction([]),
        new TasksLoadErrorAction()
      ];
    })
  ));


  taskStream$ = createEffect(() => this.actions$.pipe(
    ofType(TASK_LOAD),
    switchMap(() => this.streamService.subscribe<UserTask>(`${this.tasklistApiConfiguration.rootUrl}/task-updates`).pipe(
      map((task: UserTask) => {
        if (!task.deleted) {
          return new TasksAddAction([task]);
        }
        return new TasksRemoveAction([task.id]);
      })
    ))
  ));

}
